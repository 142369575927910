<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <certification @rowSelected="rowSelected($event)"></certification>
      </v-col>
      <v-col cols="12" md="12" lg="12">
        <producer :CsID="CsID"></producer>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import FarmLot from "@/views/TechnicalAssistance/Certification/FarmLot";
import Certification from "./Certification";
import Producer from './Producer';

export default {
  components: { FarmLot, Certification, Producer},
  data(){
    return {
      CsID: 0
    };
  },
  methods: {
    rowSelected(rows) {
      this.CsID = rows[0].CsID
    },
  },
};
</script>
