<template>
    <div>
        <s-crud
            title="Productor"
            :filter="filter"
            height="210"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            :remove="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="saveAll($event)"
            ref="crudProducer"
        >

            <template slot-scope="props">
                <v-col>
                    <v-row>
                        <s-toolbar 
                            remove 
                            color="primary" 
                            add 
                            dark 
                            @add="addProductor(props.item)"
                            @removed="removeProducer()"
                            label="Certificación Productor"
                        >
                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col lg="6" class="s-col-form" cols="6">
                            <!-- :text="props.item.PrdText"
                                v-model="props.item.PrdID" -->
                            <s-select-producer 
                                clearable
                                return-object v-model="ObjProducer"
                                label="Productores" 
                            >
                            </s-select-producer>
                        </v-col>
                        <v-col lg="6" class="s-col-form" cols="6">
                            <s-select-Agricultural  :producerID="ObjProducer.PrdID" clearable  label="Fundo" id='FagID' return-object v-model="ObjFundo" > <!-- ObjFundo -->
                            </s-select-Agricultural>
                        </v-col>
                        <v-col>
                            <v-data-table
                            v-model="arrayProducer"
                            :headers="headersDataTable"
                            :items="arrayCertificationProducer"
                            item-key="FagID"
                            show-select 
                            class="elevation-1">
                            </v-data-table>
                        </v-col>
                        <v-container>
                            <v-row justify="center">
                                <v-col lg="6">
                                    <v-btn @click="clearOption()" small block><v-icon x-small left>fas fa-undo-alt</v-icon>Limpiar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </template>

            <template v-slot:req="{ row }">
                <v-btn icon color="primary" v-if="row.req == 1" x-small  @click="showModal">
                    <v-icon>fas fa-eye</v-icon>
                </v-btn>
            </template>

            

        </s-crud>
        <certification-requirements :dialog="dialog" :CsID="CsID" :CpID="CpID" @hideModal="hideModal" @recharge="recharge"></certification-requirements>
    </div>
</template>

<script>
import _SCertificationProducerService from "@/services/Technicalassistance/CertificationProducer";
import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import SSelectAgricultural from "@/components/HarvestSchedule/SSelectAgricultural";
import CertificationRequirements from '../../../components/Utils/CertificationSchedule/CertificationRequirements.vue';

export default {
  components: {SSelectProducer, SToolbarPerson, SSelectAgricultural, CertificationRequirements},
  props: {
    CsID: { type: Number, default: 0 },
  },
  data: () => ({
      dialog: false,
      CpID: 0,
      arrayProducer: [],
      ObjFundo:{

    },
    ObjProducer:{
      ProducerObj:null,
    },
    ReturPerson: null,
    dni: "",
    plate: "",
    filter: {},
    itemSelected: {},
    arrayCertificationProducer: [],
    objArticle:{
        
    },
    ObjTransport:{
            Transport:null,
    },
    configDriverVehicle: {
        model: {
            CpID: "ID",
            CsID: "int",
            FagName: "string",
            PrdCardName: "string",
            SecStatus: "int",
            req: "int"
        },
        service: _SCertificationProducerService,
        headers: [
            {text: "ID", value: "CpID"},
            {text: "Productor", value: "PrdCardName"},
            {text: "Fundo", value: "FagName"},
            {text: "Requisitos", value: "req"},
        ],
    },
    headersDataTable:[
        {text:"ID",value:"FagID"},
        {text: "Productor", value: "ProducerName"},
        {text: "Fundo", value: "FagName"},
    ],
  }),
  methods: {
    saveAll()
    {
        this.$fun.alert("Seguro de guardar", "question").then((r) => {
            if(r.value){
                _SCertificationProducerService
                .save(this.arrayCertificationProducer, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.clearOption();
                    this.$refs.crudProducer.refresh();
                })
            }
        })
        return;
    },
    addProductor(item){
        let obj = {
            FagID: this.ObjFundo.FagID,
            ProducerName: this.ObjProducer.PrdCardName,
            FagName: this.ObjFundo.FagName,
            PrdID: this.ObjProducer.PrdID,
            CsID: this.CsID
        };
        this.arrayCertificationProducer.push(obj);
        this.clearForm();
    },
    removeProducer(){
        this.arrayProducer.forEach((element) => {
            this.arrayCertificationProducer = this.arrayCertificationProducer.filter(
            (x) => x.FagID != element.FagID
            );
        });
    },
    clearForm()
    {
        this.ObjFundo = null;
    },

    clearOption()
    {
        this.arrayCertificationProducer = []
    },
    hadReturnPerson(value, item){
        item.NtpFullName = value.NtpFullName;
        item.DrvID = value.PrsID;
        this.dni = value.PrsDocumentNumber;
    },
    Initialize() {
        this.filter = {CsID: this.CsID };
    },
    rowSelected(rows)
    {
        
        if (rows.length > 0) {
            this.itemSelected = rows;
            this.CpID = rows[0].CpID;
        }
    },
    showModal()
    {
        this.dialog = true;
    },
    hideModal()
    {
        this.dialog = false;
    },
    recharge(){
        this.$refs.crudProducer.refresh();
    }

    /* save(item){
        item.VehID = this.ObjTransport.VehID;
        item.SecStatus = 1;
        item.save();
        this.Objvehiculo = {}
    }, */
    
  },

  watch:{
      CsID(){
          this.Initialize();
      }
  },
  created() {
    this.filter = {CsID: this.CsID};
  },
};
</script>
