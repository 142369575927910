<template>
    <div>
        <s-crud
            title="Cronograma de Certificaciones"
            :filter="filter"
            height="150"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            :edit="this.$fun.getSecurity().IsLevelAdmin"
            :remove="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="save($event)"
            ref="crudschechulecertification"
        >

            <template slot-scope="props">
                <v-col>
                    <v-row>
                        <v-col lg="6" class="s-col-form" cols="12">
                            <s-date v-model="props.item.CsDate" label="Emisión" />
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <!-- <s-select-type-certification full autofocus label="Tipo Certificación" id="TcID" @input="input($event)" :text="props.item.Certification" return-object v-model="objCertification"></s-select-type-certification> -->
                            <s-select-type-certification full autofocus label="Tipo Certificación" id="TcID" @input="input($event)" v-model="props.item.TcID"></s-select-type-certification>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="12" class="s-col-form" cols="12">
                            <!-- <s-select-type-certification-detail full :TcID="TcID" label="Adenda" @cleanFields="cleanFields" :text="props.item.Adenda" return-object v-model="objCertificationDetail"></s-select-type-certification-detail> -->
                            <s-select-type-certification-detail full :TcID="TcID" label="Adenda" @cleanFields="cleanFields" :text="props.item.Adenda" v-model="props.item.TcdID"></s-select-type-certification-detail>
                        </v-col>
                    </v-row>
                </v-col>
            </template>

            <template v-slot:Certificar="{ row }">
                <v-btn icon color="primary" v-if="verifyCertify(row.CsDate)" :disabled="verifyScheduleStatus(row.CsScheduleStatus)"   @click="certify(row.CsID)" x-small>
                <v-icon>fas fa-stamp</v-icon>
                </v-btn>
            </template>


            <template v-slot:CsScheduleStatus="{ row }">
                <v-chip style="margin:0px" x-small :color="row.CsScheduleStatus == 1 ? 'success' : 'error'">
                {{ row.CsScheduleStatus == 1 ? "Completa" : "Sin Certificar" }}
                </v-chip>
            </template>

        </s-crud>
    </div>
</template>

<script>
import _CertificacionScheduleService from "@/services/Technicalassistance/CertificationScheduleService";
import SSelectTypeCertification from '@/components/Utils/Certification/sSelectTypeCertification.vue';
import SSelectTypeCertificationDetail from '../../../components/Utils/Certification/sSelectTypeCertificationDetail.vue';

export default {
  components: {SSelectTypeCertification, SSelectTypeCertificationDetail },
  props: {

  },
  data: () => ({
      dateCertify : true,
    ReturPerson:
     null,
    dni: "",
    plate: "",
    TcID: 0,
    objCertification: {
      certificationObj:null,
    },
    objCertificationDetail: {
      certificacionDetailObj: null
    },
    itemSelected: {},
    objArticle:{
        
    },
    ObjTransport:{
            Transport:null,
        },
    configDriverVehicle: {
        model: {
            CsID: "ID",
            TcID: "string",
            TcdID: "string",
            Certification: "string",
            Adenda: "string",
            CsDate: "date",
            Certificar: "int",
            CsScheduleStatus: "int"
        },
        service: _CertificacionScheduleService,
        headers: [
            {text: "ID", value: "CsID"},
            {text: "Certificación", value: "Certification"},
            {text: "Adenda", value: "Adenda"},
            {text: "Fecha", value: "CsDate"},
            {text: "Certificar", value: "Certificar"},
            {text: "Estado", value: "CsScheduleStatus"},
        ],
    },
  }),
  methods: {
      verifyCertify(CsDate)
      {
          const date = new Date();
          var dayc = date.getDate();
          if(dayc < 10){
              dayc = '0'+date.getDate();
          }
          const dateString = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + dayc
          if(CsDate == dateString){
              return true;
          }else{
               return false;
          }
      },
      certify(CsID)
      {
        this.$fun.alert("¿Seguro de certificar?", "question").then((r) => {
            if(r.value){
                _CertificacionScheduleService.masscertification(CsID, this.$fun.getUserID())
                .then((r) => {
                    this.$fun.alert("Certificados Correctamente", "success");
                    this.$refs.crudschechulecertification.refresh();
                 //   this.$ref.fresh("crudschechulecertification"); NO VOLVER HACER
                })
            }
        });

      },
      verifyScheduleStatus(CsScheduleStatus)
      {
          if (CsScheduleStatus == 1)
          {
              return true;
          }
          else{
              return false;
          }
      },
    cleanFields()
    {
      this.objCertificationDetail.TcdID = null;
    },
    input(value)
    {
      if(value){
        this.TcID = value;
      }
      
    },
    returnObject(){
        alert();
    },
    Initialize() {
      this.filter = {  };
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
            this.$emit('rowSelected', rows);
        }
    },

    save(item){
        /* if(Object.keys(this.objCertification).length === 1){
            this.$fun.alert("Ingrese certificación", "error");
            return;
        } */
        console.log('data send', item);
        item.SecStatus = 1;
        //item.TcID = this.objCertification.TcID;
        //item.TcdID = this.objCertificationDetail.TcdID;
        console.log('itemm', this.objCertificationDetail)
        item.save()
        .then((r) => {
            this.objCertification = {};
            this.objCertificationDetail = {};
         //   this.$ref.fresh("crudschechulecertification");
        });
    },
    
  },

  watch:{
      
  },
  created() {
    this.filter = {};
  },
};
</script>
