import Service from "../Service";

const resource = "certificationproducer/";

export default {
    save(tcs, requestID) {
        return Service.post(resource + "save", tcs, {
            params: {requestID}
        });
    },

    pagination(parameters, requestID){
        return Service.post(resource + "pagination", parameters, {
            params: {requestID}
        });
    }
}